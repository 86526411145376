const POLICY_COOKIE_NAME = "cookies_policy";
const POLICY_COOKIE_DURATION_DAYS = 365;

const PREFERENCES_COOKIE_NAME = "cookie_preferences_set";
const PREFERENCES_COOKIE_DURATION_DAYS = 365;

export {
  POLICY_COOKIE_NAME,
  POLICY_COOKIE_DURATION_DAYS,
  PREFERENCES_COOKIE_NAME,
  PREFERENCES_COOKIE_DURATION_DAYS,
};
